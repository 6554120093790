.c-primary-menu {
	@include screen(md) {
		margin-top: 2.4rem;
		width: 100%;
		overflow: hidden;
		border-radius: 0.8rem;
		display: none;

		body.--mobile-menu-active & {
			display: block;
		}
	}
	&__items {
		color: var(--color-white);
		font-size: var(--text-base);
		line-height: var(--lead-base);
		display: flex;
		align-items: center;
		column-gap: 4rem;
		font-weight: 600;
		flex-wrap: wrap;
	}

	&__item {
		@include screen(md) {
			flex: 0 0 100%;
		}
	}

	&__link {
		color: var(--color-white);
		transition: background 0.2s var(--default-transition), color 0.2s var(--default-transition);

		@include screen(md) {
			display: block;
			width: 100%;
			padding: 1.6rem 1.6rem;
			color: var(--color-purple-900);
		}

		&:hover {
			color: var(--color-primary-jade);
		}

		&.c-primary-menu__button {
			transition: none;
		}
	}
}

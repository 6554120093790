.u-float-right {
	float: right;
}

.u-float-left {
	float: left;
}

.u-float-none {
	float: none;
}

.u-float-clear {
	clear: both;
}

@each $size in $screen-sizes-mobile-first {
	@include screen-mobile-first($size){
		.u-float-right\@#{$size} {
			float: right;
		}

		.u-float-left\@#{$size} {
			float: left;
		}

		.u-float-none\@#{$size} {
			float: none;
		}

		.u-float-clear\@#{$size} {
			clear: both;
		}
	}
}

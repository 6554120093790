.c-section-heading {
	&__label {
		text-align: center;
		color: var(--color-primary-purple);
		text-transform: uppercase;
		font-weight: 600;
		font-size: 1.6rem;
		display: block;

		@include screen(md) {
			text-align: left;
		}
	}

	&__title {
		margin: 0 auto;
		text-align: center;
		font-size: var(--text-3xl);
		line-height: var(--lead-3xl);
		line-height: 1.25;
		font-weight: 700;
		margin-bottom: 1.6rem;
		max-width: 90%;
		z-index: 1;
		position: relative;

		@include screen(md) {
			font-size: var(--text-2xl);
			line-height: var(--lead-2xl);
			text-align: left;
			max-width: 100%;
		}
	}

	&__copy {
		font-size: 2.2rem;
		line-height: 1.5;
		text-align: center;

		@include screen(md) {
			text-align: left;
		}
	}
}

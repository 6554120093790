.p-contact {
	input,
	textarea {
		display: block;
		width: 100%;
		font-size: 14px;
		line-height: 1.5;
		height: 48px;
		border-radius: 3px;
		border: 1px solid var(--color-gray-100);
		padding: 0 16px;
		position: relative;
		outline: none;
		color: #000;
		margin-bottom: 24px;
		&:focus {
			box-shadow: 0 0 0px 4px var(--color-purple-50);
			border-color: var(--color-primary-purple);
		}
	}

	textarea {
		padding: 16px;
		height: auto;
		margin: 0;
	}

	.ginput_counter {
		margin-top: 4px;
		font-size: 14px;
		color: var(--color-gray-500);
	}

	.gfield_error input[type='text'],
	.gfield_error input[type='email'],
	.gfield_error textarea {
		box-shadow: 0 0 0px 4px hsla(0deg 100% 50% / 0.1);
		border-color: red;
	}

	input[type='submit'] {
		appearance: none;
		border-radius: 8px 0;
		color: #fff;
		background-color: var(--color-primary-purple);
		border-color: var(--color-primary-purple);
		font-size: 14px;
		padding: 8px 24px;
		cursor: pointer;
		font-weight: 600;
		margin-top: 24px;
	}

	.gform_validation_errors {
		display: flex;
		background: #ffeeee;
		border: 2px solid #ffd1d1;
		border-radius: 4px;
		padding: 8px;
		margin-bottom: 16px;
		display: none;
	}

	.gfield_validation_message {
		color: red;
		font-weight: 600;
		position: relative;
		margin-bottom: 20px;
		margin-top: -20px;
		padding-left: 3px;
	}

	.gfield--type-textarea .gfield_validation_message {
		text-align: right;
	}

	.gform_submission_error {
		font-weight: 600;
		font-size: 12px;
		color: #a32a2a;
	}

	label {
	}

	.gfield_required_text {
		display: none !important;
	}

	.gfield_required {
		&::before {
			content: '*';
			color: red;
		}
	}
	.gfield_label,
	.gform-field-label--type-sub {
		font-weight: 700;
		font-size: 14px;
		margin-bottom: 8px;
		color: #000;
		display: block;
	}

	.gfield_label_before_complex {
		font-size: 18px;
		margin-bottom: 16px;
	}

	&__group {
		display: flex;
		flex-wrap: wrap;
		gap: 40px;
		justify-content: space-between;
	}

	&__left {
		flex: 0 0 400px;

		@include screen(md) {
			flex: 0 0 100%;
		}
	}

	&__right {
		padding-left: 40px;
		flex: 1;

		@include screen(md) {
			padding: 0;
			flex: 0 0 100%;
		}
	}

	p a svg {
		fill: var(--color-primary-purple);
	}

	.s-prose a {
		font-weight: 600;
		color: var(--color-gray-600);

		svg {
			margin-top: 1px;
		}

		&:hover {
			color: var(--color-primary-purple);
		}
	}

	.gform_confirmation_message {
		font-size: 24px;
		text-align: center;
		padding-top: 40px;
	}

	.gfield--type-honeypot {
		display: none;
	}
}

.p-pricing {
	.c-page-header__inner {
		display: flex;
	}

	.c-page-header {
		padding-bottom: 8rem;
		margin-bottom: -14rem;

		@include screen(md) {
			margin: 0;
		}
	}
}

.p-pricing-page-header-inner {
	display: flex;
	justify-content: space-between;
	width: 100%;

	@include screen(md) {
		flex-wrap: wrap;
	}
}

.p-pricing-page-header-content {
	width: 36%;

	@include screen(lg) {
		width: 40%;
		margin-right: 8rem;
	}

	@include screen(md) {
		width: 100%;
		margin-right: 0;
	}
}

.p-pricing-heading-list {
	margin-top: 6rem;

	&__title {
		display: inline-block;
		font-size: 2rem;
		font-weight: 700;
		color: #fff;
		margin-bottom: 1.6rem;
	}

	&__item {
		font-size: 1.8rem;
		font-weight: 400;
		color: var(--color-gray-200);
		padding-left: 2.4rem;
		line-height: 1.5;

		&::before {
			content: url("data:image/svg+xml,%3Csvg id='Layer_2' data-name='Layer 2' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16.62 12'%3E%3Cdefs%3E%3Cstyle%3E .cls-1 %7B fill: %230cebc7; %7D %3C/style%3E%3C/defs%3E%3Cg id='Layer_1-2' data-name='Layer 1'%3E%3Cpath id='Path_66' data-name='Path 66' class='cls-1' d='m5.37,12c-.47,0-.93-.19-1.26-.52L.3,7.67c-.41-.41-.4-1.08.02-1.49.41-.4,1.06-.4,1.47,0l3.59,3.59L14.86.28c.42-.4,1.09-.38,1.49.05.38.4.38,1.03,0,1.44L6.62,11.48c-.33.34-.79.52-1.26.52'/%3E%3C/g%3E%3C/svg%3E");
			position: absolute;
			left: 0;
			width: 1.6rem;
		}
	}

	&__item+&__item {
		margin-top: 0.8rem;
	}
}

.p-pricing-page-header-form {
	&__disclaimer {
		font-size: 12px;
		display: block;
		margin-top: 16px;
		color: var(--color-gray-600);

		&.--table {
			font-size: 14px;
			margin-top: 40px;
		}
	}

	&__tabs {
		display: flex;
		align-items: flex-end;
	}

	&__tab {
		background: var(--color-gray-100);
		display: flex;
		align-items: center;
		justify-content: center;
		width: 50%;
		border-radius: 1.6rem 1.6rem 0 0;
		font-size: 1.6rem;
		font-weight: 600;
		color: var(--color-gray-600);
		padding: 0.8rem 1.6rem;
		text-align: center;
		line-height: 1.25;
		cursor: pointer;

		@include screen(sm) {
			font-size: 1.4rem;
		}

		&:first-child {
			border-radius: 1.6rem 0 0 0;
		}

		&:last-child {
			border-radius: 0 1.6rem 0 0;
		}

		span {
			display: block;
		}

		&.--is-active {
			border-radius: 1.6rem 1.6rem 0 0;
			background: #fff;
			color: var(--color-primary-purple);
			font-weight: 700;
			padding: 1.6rem;
			box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
			z-index: 1;
		}
	}

	&__button .c-button {
		padding: 1.8rem 4rem;
		font-size: 1.8rem;
	}

	&-container {
		width: 64rem;
		margin-top: -6rem;

		@include screen(md) {
			margin: 0;
			width: 100%;
			max-width: 90%;
			margin: 0 auto;
			margin-top: 8rem;
		}

		@include screen(sm) {
			max-width: 100%;
		}
	}

	&__content {
		background: #fff;
		border-radius: 0 0 1.6rem 1.6rem;
		padding: 3.2rem;
		display: flex;
		flex-direction: column;
		position: relative;
		z-index: 1;
		box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 25px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;

		@include screen(lg) {
			padding: 1.6rem 1.2rem;
		}
	}

	&__heading {
		display: block;
		font-size: 2.2rem;
		font-weight: 700;
		line-height: 1.25;
		color: #000;
		text-align: center;
		margin: 0 auto;
		margin-bottom: 4rem;
	}

	&__options {
		display: flex;
		flex-direction: column;
		margin-bottom: 2.4rem;
	}

	&__option {
		display: flex;
		padding: 1.2rem 1.6rem;
		align-items: center;
		cursor: pointer;
		border: 3px solid transparent;
		border-radius: 20px;
		transition: border-color var(--default-transition) 0.1s;
		position: relative;

		@include screen(sm) {
			border-color: var(--color-gray-50);
		}

		&:after {
			// width: 100%;
			// content: '';
			// height: 2px;
			// background: var(--color-gray-50);
			// position: absolute;
			// left: 0;
			// bottom: -5px;
		}

		&+& {
			margin-top: 0.4rem;
		}

		&:hover {
			border-color: var(--color-blurple-100);
		}

		&:active {
			transform: translateY(2px);
		}

		&.--is-recommended {
			border-color: var(--color-jade-100);
		}

		&.--is-selected {
			border-color: var(--color-primary-blurple);
			box-shadow: rgba(123, 87, 255, 0.25) 0px 7px 29px 0px;
			z-index: 1;

			&:after {
				display: none;
			}
		}

		@include screen(md) {
			padding: 0.8rem;
			flex-wrap: wrap;
			align-items: flex-start;
		}
	}

	&__option-radio {
		padding-right: 1.6rem;

		@include screen(md) {
			padding-top: 0.4rem;
		}
	}

	&__option-content {
		display: flex;
		flex-direction: column;

		@include screen(md) {
			flex: 1;
		}
	}

	&__option-title {
		display: block;
		font-size: 1.8rem;
		font-weight: 700;
		color: var(--color-gray-800);
		line-height: 1;
		padding-bottom: 0.4rem;

		@include screen(md) {
			font-size: 1.6rem;
		}

		.--is-selected & {
			color: var(--color-primary-purple);
		}
	}

	&__option-description {
		color: var(--color-gray-600);
		font-size: 1.3rem;

		@include screen(md) {
			font-size: 1.1rem;
		}
	}

	&__option-price {
		display: flex;
		align-items: flex-end;
		margin-left: auto;

		@include screen(md) {
			flex: 1;
			margin-left: 0;
		}
	}

	&__option-price-value {
		font-size: 4rem;
		font-weight: 700;
		color: var(--color-gray-800);
		font-variant-numeric: tabular-nums;
		display: block;
		padding-right: 0.6rem;
		line-height: 1;

		sup {
			font-size: 0.5em;
			top: -0.7em;
		}

		.--is-selected & {
			color: var(--color-primary-purple);
		}
	}

	&__option-price-description {
		display: block;
		font-size: 1.1rem;
		color: var(--color-gray-600);
		position: relative;
		top: -0.2rem;
	}

	&__option-recommended {
		background: var(--color-primary-jade);
		color: var(--color-blue-800);
		font-weight: 700;
		font-size: 1.2rem;
		padding: 1rem 1.4rem 1rem 0.8rem;
		border-radius: 0 8px 8px 0;
		position: absolute;
		right: -130px;
		box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
		animation: bounce-in-left 2s infinite forwards;
		display: none;
		height: 37px;

		.--is-recommended & {
			display: block;
		}

		@include screen(sm) {
			top: 10px;
			right: 10px;
			animation: none;
			border-radius: 8px;
			font-size: 1rem;
			padding: 0.4rem 0.8rem;
			height: auto;

			&::before {
				display: none;
			}
		}

		&::before {
			content: '';
			height: 0;
			width: 0;
			position: relative;
			border-right: solid 24px var(--color-primary-jade);
			border-bottom: solid 20px transparent;
			border-top: solid 20px transparent;
			position: absolute;
			left: -20px;
			top: 0;
			border-right-width: 20px;
			border-bottom-width: 19px;
			border-top-width: 19px;
		}

		// &.--is-left {
		// 	left: -140px;
		// 	right: auto;
		// 	border-radius: 8px 0 0 8px;
		// 	animation: bounce-in-right 2s infinite forwards;

		// 	&::before {
		// 		border-left: solid 24px var(--color-primary-jade);
		// 		border-bottom: solid 20px transparent;
		// 		border-top: solid 20px transparent;
		// 		left: auto;
		// 		right: -24px;
		// 		position: absolute;

		// 		border-right-width: 0;
		// 		border-bottom-width: 19px;
		// 		border-top-width: 19px;
		// 	}
		// }
	}

	&__option-content-container {
		flex: 1;
		display: flex;
		flex-wrap: wrap;

		@include screen(sm) {
			flex-direction: column;
			gap: 0.8rem;
		}
	}
}

.p-pricing-page-slider {
	max-width: 80%;
	margin: 0 auto;
	margin-bottom: 7.2rem;

	&__pips {
		display: flex;
		justify-content: space-between;
		font-weight: 700;
		color: var(--color-gray-400);
		font-size: 1.8rem;
		margin-bottom: 0.4rem;
	}

	.noUi-connect {
		background: var(--color-primary-jade);
	}

	.noUi-target {
		border-radius: 9999px;
		border: none;
		box-shadow: none;
		background: var(--color-gray-100);
	}

	.noUi-horizontal {
		height: 7px;
	}

	.noUi-horizontal .noUi-handle {
		width: 28px;
		height: 28px;
		border-radius: 100%;
		background: var(--color-jade-500);
		box-shadow: none;
		border: 3px solid #fff;
		right: -14px;
		top: -10px;
		cursor: pointer;
		box-shadow: rgba(70, 212, 118, 0.5) 0px 0px 14px 0px;

		&::before {
			content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='1em' fill='white' viewBox='0 0 256 512'%3E%3C!--! Font Awesome Free 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --%3E%3Cpath d='M9.4 278.6c-12.5-12.5-12.5-32.8 0-45.3l128-128c9.2-9.2 22.9-11.9 34.9-6.9s19.8 16.6 19.8 29.6l0 256c0 12.9-7.8 24.6-19.8 29.6s-25.7 2.2-34.9-6.9l-128-128z'/%3E%3C/svg%3E");
			border: none;
			background: none;
			width: 10px;
			left: 4px;
			top: 3px;
		}

		&::after {
			content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='1em' viewBox='0 0 256 512'%3E%3C!--! Font Awesome Free 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --%3E%3Cstyle%3Esvg%7Bfill:%23ffffff%7D%3C/style%3E%3Cpath d='M246.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-128-128c-9.2-9.2-22.9-11.9-34.9-6.9s-19.8 16.6-19.8 29.6l0 256c0 12.9 7.8 24.6 19.8 29.6s25.7 2.2 34.9-6.9l128-128z'/%3E%3C/svg%3E");
			border: none;
			background: none;
			width: 10px;
			right: 2px;
			top: 3px;
			left: inherit;
		}
	}

	.noUi-horizontal .noUi-tooltip {
		bottom: 140%;
	}

	.noUi-tooltip {
		border: none;
		background: var(--color-gray-900);
		color: #fff;
		font-size: 2rem;
		font-weight: 700;
		font-variant-numeric: tabular-nums;
		padding: 0 8px;

		&::after {
			content: '';
			background-image: url("data:image/svg+xml,%3Csvg id='Layer_2' data-name='Layer 2' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8.6 7.94'%3E%3Cdefs%3E%3Cstyle%3E .cls-1 %7B fill: %2325252d; %7D %3C/style%3E%3C/defs%3E%3Cg id='Layer_1-2' data-name='Layer 1'%3E%3Cg id='Group_2421' data-name='Group 2421'%3E%3Cg id='Group_2420' data-name='Group 2420'%3E%3Cg id='Group_2419' data-name='Group 2419'%3E%3Cpath id='Polygon_5' data-name='Polygon 5' class='cls-1' d='m5.17,7.43c-.27.48-.88.66-1.36.39-.16-.09-.3-.23-.39-.39L.13,1.49C-.14,1,.03.39.51.13.66.04.83,0,1,0h6.6c.55,0,1,.45,1,1,0,.17-.04.34-.13.49l-3.3,5.94Z'/%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
			position: absolute;
			bottom: -5px;
			left: 50%;
			transform: translateX(-50%);
			width: 8px;
			height: 7px;
			background-repeat: no-repeat;
			background-position: center top;
		}
	}

	&__bottom-tooltip {
		display: flex;
		flex-direction: column;
		align-items: center;
		position: absolute;
		width: 18rem;
		left: 50%;
		transform: translateX(-50%);
		bottom: -200%;
	}

	&__bottom-tooltip-values {
		display: block;
		font-weight: 700;
		color: var(--color-jade-500);
		font-size: 1.6rem;
		line-height: 1;
	}

	&__bottom-tooltip-description {
		color: var(--color-gray-900);
		font-size: 1.1rem;
		font-weight: 600;
	}
}

.p-pricing-page-radio {
	width: 2.4rem;
	height: 2.4rem;
	border: 2px solid var(--color-gray-500);
	border-radius: 9999px;
	position: relative;

	.--is-selected & {
		border-color: var(--color-primary-purple);

		&::before {
			background: var(--color-primary-purple);
			transform: scale(1) translate(-50%, -50%);
		}
	}

	&::before {
		content: '';
		background: #fff;
		position: absolute;
		left: 50%;
		top: 50%;
		transform: scale(0) translate(-50%, -50%);
		width: 1.2em;
		height: 1.2em;
		border-radius: 9999px;
		transition: all var(--default-transition) 0.2s;
		transform-origin: top left;
	}
}

@keyframes bounce-in-left {
	0% {
		transform: translateX(-8px);
	}

	50% {
		transform: translateX(0);
	}

	100% {
		transform: translateX(-8px);
	}
}

@keyframes bounce-in-right {
	0% {
		transform: translateX(8px);
	}

	50% {
		transform: translateX(0);
	}

	100% {
		transform: translateX(8px);
	}
}

.p-pricing-main-section {
	padding-top: 14rem;

	&-inner {}

	&__heading {
		margin: 0 auto;
		max-width: 80%;

		@include screen(md) {
			max-width: 100%;
		}
	}

	&__label {
		color: var(--color-primary-purple);
		text-transform: uppercase;
		font-weight: 600;
		font-size: 1.6rem;
		display: block;
		text-align: center;

		@include screen(md) {
			text-align: left;
		}
	}

	&__title {
		font-size: var(--text-3xl);
		line-height: var(--lead-3xl);
		line-height: 1.25;
		font-weight: 700;
		margin: 0 auto;
		margin-bottom: 1.6rem;
		text-align: center;

		@include screen(md) {
			font-size: var(--text-2xl);
			text-align: left;
		}
	}

	&__copy {
		font-size: 2.2rem;
		line-height: 1.5;
		text-align: center;

		@include screen(md) {
			text-align: left;
		}
	}

	&__toggle {
		margin: 8rem 0;
		position: relative;
		--width: 280px;
		--height: 40px;
		--transition: all 0.3s var(--default-transition);

		&:hover &-background {
			box-shadow: rgba(123, 87, 255, 0.4) 0px 7px 29px 0px;
		}

		@include screen(md) {
			--width: 200px;
			margin: 4rem 0;
			margin-top: 8rem;
		}

		@include screen(sm) {
			--width: 140px;
			--height: 32px;
			margin: 4rem 0;
			margin-top: 8rem;
		}

		@include screen(xs) {
			--width: 120px;
			--height: 32px;
			margin: 4rem 0;
			margin-top: 8rem;
		}
	}

	&__toggle-inner {
		display: flex;
		cursor: pointer;
		align-items: center;
		justify-content: center;
		border: 2px solid var(--color-gray-100);
		border-radius: 1000px;
		position: relative;
		width: calc(var(--width) * 3);
		margin: 0 auto;
		background: var(--color-color-gray-50);
	}

	&__toggle-background {
		background: var(--color-primary-purple);
		height: var(--height);
		width: var(--width);
		border-radius: 1000px;
		left: 0;
		position: absolute;
		transition: var(--transition);

		box-shadow: rgba(123, 87, 255, 0.25) 0px 7px 29px 0px;

		.--is-selected+*+& {
			left: var(--width);
		}

		.--is-selected+& {
			left: calc(var(--width) * 2);
		}
	}

	&__toggle-item {
		font-size: 16px;
		color: var(--color-gray-400);
		font-weight: 600;
		width: var(--width);
		text-align: center;
		height: var(--height);
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 1000px;
		z-index: 2;
		transition: var(--transition);

		@include screen(sm) {
			font-size: 10px;
		}

		@include screen(xs) {
			font-size: 9px;
		}

		&.--is-selected {
			color: #fff;
		}
	}

	&__toggle-chargebacks {
		width: 300px;
		margin: 0 auto;
		position: absolute;
		left: calc(50% - 0px);
		top: -40px;

		@include screen(md) {
			left: 40%;
		}

		@include screen(sm) {
			left: calc(50% - 150px);
		}
	}
}

.p-pricing-main-section-table {
	width: 100%;
	table-layout: fixed;
	border-collapse: separate;
	/* Don't collapse */
	border-spacing: 0;

	@include screen(sm) {
		width: 1200px;
		margin-top: 20px;
	}

	&-container {
		margin-bottom: 10rem;

		@include screen(sm) {
			width: 100%;
			overflow-x: scroll;
		}
	}

	tbody {
		overflow: hidden;
	}

	th {
		background: var(--color-gray-100);
		color: var(--color-gray-900);
		font-size: 1.4rem;
		line-height: 1;
		text-transform: uppercase;
		font-weight: 700 !important;
		letter-spacing: 0.1em;
		padding: 16px 40px;

		@include screen(lg) {
			padding: 0;
		}

		&:first-child {
			border-radius: 16px 0 0 16px;
			text-align: left;

			@include screen(md) {
				border-radius: 0;
			}
		}

		&:last-child {
			border-radius: 0 16px 16px 0;

			@include screen(md) {
				border-radius: 0;
			}
		}
	}

	td {
		padding: 16px;
		font-size: 18px;
		color: var(--color-gray-800);
		text-align: center;
		border-bottom: 2px solid var(--color-gray-100);

		@include screen(xl) {
			font-size: 16px;
		}

		@include screen(lg) {
			font-size: 14px;
		}
	}

	tr>td:first-child,
	tr>th:first-child {
		text-align: left;
		width: 340px;
		font-weight: 600;
		padding: 16px 40px;

		@include screen(lg) {
			width: 240px;
		}

		@include screen(md) {
			width: 220px;
			padding: 16px 20px;
		}
	}

	tr>td {
		font-weight: 400;
	}

	td,
	th {
		height: 80px;
		position: relative;

		@include screen(lg) {
			height: 60px;
		}
	}

	&__feature-icon {
		width: 26px;
		display: inline-block;
	}

	&__subtext {
		font-size: 14px;
		color: var(--color-gray-600);
		font-weight: 700;
		display: block;
		position: relative;
		top: -3px;
	}

	&__tooltip-icon {
		display: inline-block;
		font-size: 14px;
		fill: var(--color-gray-300);
		top: 1px;
		position: relative;
		cursor: pointer;
	}

	td.p-pricing-main-section-table__price {
		font-size: 40px;
		font-weight: 700;
		line-height: 1;

		sup {
			font-size: 0.6em;
		}
	}

	&__user-accounts td {
		position: relative;
		border-bottom: 10px solid var(--color-gray-100);

		&.--is-recommended {
			span {
				display: block;
				width: calc(100% + 8px);
				border-left: 4px solid var(--color-primary-jade);
				border-right: 4px solid var(--color-primary-jade);
				height: 10px;
				position: absolute;
				left: -4px;
				bottom: -10px;
			}
		}
	}

	th.--is-recommended {
		.p-pricing-main-section-table__recommended {
			display: block;
		}
	}

	th.--is-recommended {
		position: relative;

		span.fake-border {
			pointer-events: none;
			position: absolute;
			left: 0;
			top: 0;
			border-left: 4px solid var(--color-primary-jade);
			border-right: 4px solid var(--color-primary-jade);
			border-top: 4px solid var(--color-primary-jade);
			width: 100%;
			height: 100%;
			z-index: 2;
			border-radius: 16px 16px 0 0;
		}
	}

	td.--is-recommended {
		border-left: 4px solid var(--color-primary-jade);
		border-right: 4px solid var(--color-primary-jade);
		font-weight: 700;
	}

	tr:last-child td.--is-recommended {
		border-bottom: 4px solid var(--color-primary-jade);
	}

	&__recommended {
		background: var(--color-primary-jade);
		color: var(--color-blue-800);
		font-weight: 700;
		font-size: 1.4rem;
		padding: 0.5em 1em;
		border-radius: 4px;
		position: absolute;
		top: -12px;
		left: 50%;
		transform: translateX(-50%);
		text-transform: none;
		letter-spacing: normal;
		display: none;
		z-index: 3;

		@include screen(lg) {
			font-size: 1.1rem;
		}
	}

	@include screen(md) {

		td:first-child,
		th:first-child {
			position: sticky;
			left: 0;
			z-index: 5;
			box-shadow: rgba(100, 100, 111, 0.1) 7px 10px 9px 0;
		}

		td:first-child {
			background: Var(--color-gray-50);
		}

		th:first-child::before {
			content: '';
			width: 100%;
			height: 14px;
			background: var(--color-gray-50);
			position: absolute;
			bottom: 100%;
			left: 0;
		}
	}
}

.network-toggle {
	display: flex;
	position: relative;
	--width: 112px;
	--height: 28px;
	--transition: all 0.3s var(--default-transition);
	display: flex;
	cursor: pointer;
	align-items: center;
	justify-content: center;
	border: 2px solid var(--color-gray-100);
	border-radius: 1000px;
	position: relative;
	width: calc(var(--width) * 2);
	margin: 0 auto;
	background: var(--color-color-gray-50);
	margin-bottom: 24px;

	display: none;

	&.--is-active {
		display: flex;
	}

	&__item {
		font-size: 13px;
		color: var(--color-gray-400);
		font-weight: 600;
		width: var(--width);
		text-align: center;
		height: var(--height);
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 1000px;
		z-index: 2;
		transition: var(--transition);

		&.--is-selected {
			color: #fff;
		}
	}

	&__slide {
		background: var(--color-primary-purple);
		height: var(--height);
		width: var(--width);
		border-radius: 1000px;
		left: 0;
		position: absolute;
		transition: var(--transition);
		box-shadow: rgba(123, 87, 255, 0.25) 0px 7px 29px 0px;

		.--is-selected+& {
			left: var(--width);
		}
	}
}
.c-mobile-menu {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;

	& .c-primary-menu {
		flex: 0 0 100%;
		padding-top: 2.4rem;
	}
	& .c-primary-menu__items {
		flex-wrap: wrap;
	}

	& .c-primary-menu__item {
		flex: 0 0 100%;
	}
}

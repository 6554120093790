.p-product-tour {
	background: var(--color-gray-50);
	.c-page-header {
		max-width: 42rem;
	}

	.c-section--page-header {
		position: relative;

		z-index: 2;
	}
}

.p-product-tour-main-section {
	padding-top: 8rem;
}

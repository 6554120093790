@mixin screen($class) {
	// X-Small devices (portrait phones, less than 576px)
	@if $class == xs {
		@media (max-width: 575.98px) { @content; }
	}

	// Small devices (landscape phones, less than 768px)
	@else if $class == sm {
		@media (max-width: 767.98px) { @content; }
	}

	// Medium devices (tablets, less than 992px)
	@else if $class == md {
		@media (max-width: 991.98px) { @content; }
	}

	// Large devices (desktops, less than 1200px)
	@else if $class == lg {
		@media (max-width: 1199.98px) { @content; }
	}

	// X-Large devices (large desktops, less than 1400px)
	@else if $class == xl {
		@media (max-width: 1399.98px) { @content; }
	}
}


@mixin screen-mobile-first($class) {
	@if $class == xs {
		@media (min-width: 576px) { @content; }
	}

	@else if $class == sm {
		@media (min-width: 768px) { @content; }
	}

	@else if $class == md {
		@media (min-width: 992px) { @content; }
	}

	@else if $class == lg {
		@media (min-width: 1200px) { @content; }
	}

	@else if $class == xl {
		@media (min-width: 1400px) { @content; }
	}
}

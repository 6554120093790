.c-input {
	width: 100%;
	font-size: 1.6rem;
	border-radius: 0.4rem;
	line-height: 1.25;
	height: 4.8rem;
	padding: 1.6rem;
	color: var(--color-body);
	font-weight: 600;

	&:focus {
		outline: none;
		box-shadow: 0px 0px 0px 4px var(--color-primary-purple);
	}
}

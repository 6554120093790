.c-button {
	background: var(--color-primary-purple);
	padding: 1.2rem 4rem;
	border-radius: 0.8rem 0;
	display: flex;
	align-items: center;
	transition: all 0.2s var(--default-transition);
	font-size: 1.6rem;
	cursor: pointer;
	font-weight: 600;
	color: var(--color-white);
	justify-content: center;
	user-select: none;

	&:hover {
		background: var(--color-primary-jade);
		color: var(--color-jade-900);
	}

	&:active {
		background: var(--color-jade-500);
		transform: translateY(0.2rem);
	}

	&--primary-alt {
		&:hover {
			background: var(--color-purple-700);
			color: var(--color-white);
		}

		&:active {
			background: var(--color-purple-800);
		}
	}

	&--secondary {
		background: var(--color-primary-jade);
		color: var(--color-jade-950);

		&:hover {
			background: var(--color-jade-500);
			color: var(--color-jade-950);
		}

		&:active {
			color: var(--color-jade-950);
		}
	}

	&--icon {
		gap: 0.8rem;

		svg {
			width: 2.4rem;
			position: relative;

			top: 2px;
		}
	}

	&--ghost {
		background: none;
		padding-left: 0.4rem;
		padding-right: 0.4rem;

		&:hover {
			background: none;
		}
	}

	&--ghost-white {
		color: var(--color-blue-50);
		&:hover {
			color: var(--color-blue-100);
		}
	}
}

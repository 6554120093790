.o-grid {
	display: flex;
	align-items: flex-start;
	margin-bottom: 3.2rem;
	margin-left: -1.6rem;
	margin-right: -1.6rem;
	flex-wrap: wrap;

	& > * {
		flex-shrink: 0;
	    width: 100%;
	    max-width: 100%;
		padding-left: 1.6rem;
		padding-right: 1.6rem;
	}

	&__column {
		flex: 1 0 0%;
		width: 100%;
		padding-left: 1.6rem;
		padding-right: 1.6rem;
	}

	@for $i from 1 through 12 {
		&__column-#{$i} {
			width: calc($i / 12 * 100%);
			flex: 0 0 auto;
			padding-left: 1.6rem;
			padding-right: 1.6rem;
		}
	}

	@each $size in $screen-sizes-mobile-first {
		@include screen-mobile-first($size){
			@for $i from 1 through 12 {
				&__column-#{$i}\@#{$size} {
					width: calc($i / 12 * 100%);
					flex: 0 0 auto;
					padding-left: 1.6rem;
					padding-right: 1.6rem;
				}

				&__column-#{$i}\@#{$size} {
					width: calc($i / 12 * 100%);
					flex: 0 0 auto;
					padding-left: 1.6rem;
					padding-right: 1.6rem;
				}
			}
		}
	}
}

.c-home-features {
	padding-top: 12rem;
	padding-bottom: 12rem;

	&__label {
		color: var(--color-primary-purple);
		text-transform: uppercase;
		font-weight: 600;
		font-size: 1.6rem;
		display: block;
		text-align: center;
		@include screen(md) {
			text-align: left;
		}
	}

	&__heading {
		font-size: var(--text-3xl);
		line-height: var(--lead-3xl);
		line-height: 1.25;
		font-weight: 700;
		margin: 0 auto;
		margin-bottom: 1.6rem;
		text-align: center;

		@include screen(md) {
			font-size: var(--text-2xl);
			text-align: left;
		}
	}

	&__subheading {
		text-align: center;
		font-size: 2.2rem;
		line-height: 1.5;
		color: var(--color-gray-700);
		margin: 0 auto;
		max-width: 80%;
		margin-bottom: 4.8rem;
		@include screen(md) {
			text-align: left;
			max-width: 100%;
		}
	}

	&__item {
		width: 100%;
		border-radius: 0.8rem;
		border: solid 0.1rem var(--color-gray-200);
		padding: 2.4rem;
		display: flex;
		cursor: pointer;
		transition: all 0.2s var(--default-transition);
		background: var(--color-white);

		& + & {
			margin-top: 1.6rem;
		}

		&:hover {
			background: var(--color-purple-50);
			border-color: var(--color-purple-50);
		}

		&:active {
			transform: translateY(0.4rem);
		}

		&.--is-active {
			border: solid 0.1rem var(--color-primary-purple);
			background: var(--color-primary-purple);
			color: var(--color-white);

			.c-home-features__description,
			.c-home-features__title {
				color: var(--color-white);
			}

			.c-home-features__icon {
				border-color: var(--color-primary-purple);
				background: var(--color-primary-jade);
				background: linear-gradient(
					90deg,
					var(--color-primary-jade) 0%,
					var(--color-primary-mint) 100%
				);
				color: var(--color-primary-purple);
			}
		}
	}

	&__title {
		font-size: 2.2rem;
		font-weight: 700;
		line-height: 1.25;
		margin-bottom: 0.4rem;
	}

	&__description {
		font-size: 1.6rem;
		line-height: 1.5;
		color: var(--color-gray-700);
	}

	&__icon {
		width: 4.8rem;
		height: 4.8rem;
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 1.2rem 0;
		border: solid 0.2rem var(--color-gray-400);
		color: var(--color-gray-500);

		svg {
			width: 2.4rem;
		}
	}

	&__content {
		padding-left: 2.4rem;
		flex: 1;
		margin-top: -0.6rem;
	}

	&__features {
		display: flex;
		gap: 6.4rem;
	}

	&__graphic-container {
		flex: 0 0 54rem;
		background: var(--color-gray-100);
		display: flex;
		padding: 4rem;
		align-items: center;
		justify-content: center;
		border-radius: 2.4rem 0;

		@include screen(md) {
			flex: 0 0 100%;
			display: none;
		}
	}

	&__graphic {
		width: 100%;
		display: none;
		position: relative;

		&.--is-active {
			display: block;
		}

		& span {
			position: absolute;
			color: #fff;
			font-size: 80px;
			font-weight: 700;
			display: flex;
			align-items: center;
			justify-content: center;
			width: 100%;
			height: 100%;
			background: rgba(108, 78, 217, 0.5);
		}
	}
}

.c-mobile-button {
	background: var(--color-primary-purple);
	border-radius: 0.8rem 0;
	padding: 0.8rem;
	display: none;
	align-items: center;
	cursor: pointer;
	color: var(--color-white);

	svg {
		width: 2.4rem;
	}

	&:hover {
		background: var(--color-primary-jade);
		color: var(--color-jade-900);
	}
}

.c-social-media {
	color: var(--color-white);
	display: flex;
	gap: 0.8rem;
	font-size: 1.4rem;

	&__link {
		background: var(--color-primary-purple);
		display: flex;
		border-radius: 0.3em 0;
		padding: 0.4em;
		width: 2em;
		height: 2em;
		align-items: center;
		justify-content: center;
		transition: all 0.12s var(--default-transition);

		&:hover {
			background: var(--color-primary-jade);
			color: var(--color-jade-900);
		}

		&:active {
			transform: translateY(0.2rem);
		}
	}
}

.c-providers {
	padding: 4rem 0;

	&__title {
		color: var(--color-blue-800);
		font-size: 2.2rem;
		font-weight: 600;
		margin: 0 auto;
		margin-bottom: 3.2rem;
		text-align: center;

		@include screen(lg) {
			max-width: 76rem;
		}
	}

	&__items {
		display: flex;
		align-items: center;
		justify-content: center;
		gap: 8rem;

		@include screen(lg) {
			gap: 4rem;
		}

		@include screen(md) {
			gap: 2rem;
		}

		@include screen(sm) {
			flex-wrap: wrap;
			row-gap: 4.8rem;
		}
	}

	&__item {
		display: flex;
		align-items: center;
		justify-content: center;

		@include screen(sm) {
			flex-basis: calc(50% - 2rem);
			flex-grow: 0;
		}
	}

	&__logo {
		max-height: 4rem;

		@include screen(sm) {
			max-height: 4rem;
			max-width: 13rem;
		}
	}
}
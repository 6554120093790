.c-page-header {
	padding: 12rem 0;
	position: relative;

	&__subtitle {
		display: inline-block;
		font-size: 1.6rem;
		line-height: 1.25;
		color: var(--color-primary-jade);
		font-weight: 600;
		text-transform: uppercase;
	}

	&__title {
		color: var(--color-white);
		font-size: 4.8rem;
		line-height: 1.25;
		font-weight: 700;
		margin-bottom: 1.6rem;

		@include screen(lg) {
			font-size: var(--text-3xl);
		}
	}

	&__mini-description {
		font-size: 2.2rem;
		line-height: 1.5;
		color: var(--color-gray-200);
		max-width: 68rem;

		a {
			&:hover {
				color: var(--color-primary-jade);
			}
		}
	}

	&__buttons {
		margin-top: 2.4rem;
		display: flex;
		gap: 2.4rem;
	}
}

.c-home-cards {
	padding-top: 12rem;
	padding-bottom: 12.8rem;

	@include screen(lg) {
		padding-top: 0;
	}

	&__items {
		display: flex;
		flex-wrap: wrap;
		gap: 4rem;
	}

	&__item {
		background: var(--color-white);
		width: calc(33.33% - 4rem + (4rem / 3));
		padding: 4rem;
		border-radius: 2.4rem 0;

		@include screen(lg) {
			width: 100%;
		}
	}

	&__logo {
		width: 4rem;
		margin-bottom: 1.6rem;
	}

	&__title {
		font-size: 2.2rem;
		font-weight: 700;
		margin-bottom: 1.2rem;
	}

	&__content {
		font-size: 1.8rem;
		line-height: 1.5;
		color: var(--color-gray-700);
	}

	&__label {
		text-align: center;
		color: var(--color-primary-purple);
		text-transform: uppercase;
		font-weight: 600;
		font-size: 1.6rem;
		display: block;

		@include screen(md) {
			text-align: left;
		}
	}

	&__heading {
		margin: 0 auto;
		text-align: center;
		font-size: var(--text-3xl);
		line-height: var(--lead-3xl);
		line-height: 1.25;
		font-weight: 700;
		margin-bottom: 4.8rem;
		max-width: 90%;

		@include screen(md) {
			font-size: var(--text-2xl);
			line-height: var(--lead-2xl);
			text-align: left;
			max-width: 100%;
		}
	}
}

.c-footer {
	padding-top: 160px;
	margin-top: -160px;
	color: var(--color-white);

	.c-cta {
		margin-top: -160px;
	}

	&__inner {
		padding: 8rem 0;
	}

	&__logo {
		max-width: 24rem;
	}

	&__copyright {
		padding-top: 3.2rem;
		font-size: 1.4rem;
		display: flex;
		gap: 2.4rem;
		border-top: 0.1rem solid var(--color-purple-900);
		margin-top: 4rem;
		color: var(--color-gray-400);

		& a {
			color: var(--color-white);
			&:hover {
				color: var(--color-primary-jade);
			}
		}
	}

	&__social-media {
		margin-top: 1.6rem;
	}

	&__menu {
		display: flex;
		align-items: center;
		gap: 4rem;
		font-size: 1.6rem;
		flex-wrap: wrap;
		@include screen(sm) {
			gap: 1.6rem;
		}
	}

	&__menu-item {
		@include screen(sm) {
			flex: 0 0 100%;
		}
	}

	&__menu-link {
		&:hover {
			color: var(--color-primary-jade);
		}
	}

	&__menu-button {
		@include screen(sm) {
			order: 10;
		}
	}

	&__columns {
		display: flex;
		justify-content: space-between;
		align-items: center;
		flex-wrap: wrap;
		gap: 4rem;
	}

	&__column {
		@include screen(md) {
			flex: 0 0 100%;
		}
	}
}

.c-section {
	&--bg-blue-800 {
		background: var(--color-blue-800);
		background: linear-gradient(0deg, var(--color-blue-800) 0%, var(--color-blue-900) 100%);
	}
	&--page-header {
		background: var(--color-blue-800);
		background: var(--color-blue-800) url('../img/decoration/page-header-bg@2x.png');
		background-size: 50%;
		background-position: center 20%;
		background-repeat: no-repeat;
	}
	&--bg-jade-left-to-mint-right {
		background: var(--color-primary-jade);
		background: linear-gradient(
			90deg,
			var(--color-primary-jade) 0%,
			var(--color-primary-mint) 100%
		);
	}
	&--bg-gray-50 {
		background: var(--color-gray-50);
	}
	&--padding-default {
		padding: 12.8rem 0;
	}
}

.c-header {
	margin-top: 2.4rem;
	margin-bottom: 2.4rem;
	display: flex;
	align-items: center;
	justify-content: space-between;
	flex-wrap: wrap;
	position: relative;

	.c-logo__src--mobile {
		display: none;
	}

	body.--mobile-menu-active & {
		background: var(--color-white);
		margin-left: -1.2rem;
		margin-right: -1.2rem;
		padding: 1.2rem;
		border-radius: 8px 0;
		margin-top: 1.2rem;

		.c-logo__src {
			&--desktop {
				display: none;
			}

			&--mobile {
				display: block;
			}
		}
	}
}

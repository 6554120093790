// resets stuff on old website to be removed later on new site.
//

@mixin reset-type {
	margin: 0;
	padding: 0;
	font-size: 1rem;
	line-height: 1.5;
}

@mixin reset-list {
	margin: 0;
	padding: 0;
	list-style: none;
}

@mixin reset-list-items {
	margin: 0;
	padding: 0;
}

.c-faqs {
	padding: 12rem 0;

	&__group {
		display: flex;
		gap: 12rem;
		flex-wrap: wrap;

		@include screen(md) {
			gap: 4rem;
		}

		& + & {
			margin-top: 16rem;
		}
	}

	&__content {
		flex: 0 0 30rem;
	}

	&__items {
		flex: 1;

		@include screen(md) {
			flex: 0 0 100%;
		}
	}

	&__title {
		font-weight: 700;
		font-size: 3.6rem;
		line-height: 1.25;
		margin-bottom: 1.6rem;
	}

	&__description {
		color: var(--color-gray-700);
		font-size: 1.8rem;
		line-height: 1.5;
	}

	&__item + &__item {
		margin-top: 2.4rem;
	}
	&__item-question {
		font-size: 2.2rem;
		line-height: 1.25;
		font-weight: 700;
		padding: 2rem;
		padding-right: 5rem;
		background: var(--color-gray-50);
		display: block;
		cursor: pointer;
		transition: all 0.125s var(--default-transition);
		border-radius: 0.4rem;

		&:hover {
			background: var(--color-gray-100);
		}

		&:active {
			transform: translateY(0.2rem);
		}
	}

	&__item-answer {
		color: var(--color-gray-700);
		font-size: 2rem;
		line-height: 1.5;
		padding: 2rem;
		display: none;

		p + p {
			margin-top: 2.4rem;
		}
	}

	&__item-inner {
		position: relative;
	}

	&__item-icons {
		position: absolute;
		top: 1.2rem;
		right: 1rem;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-wrap: wrap;
		padding: 4px;
		border-radius: 100px;
		transition: all 0.125s var(--default-transition);

		.c-faqs__item-question:active ~ & {
			transform: translateY(0.2rem);
		}
	}

	&__item-icons-inner {
		border-radius: 500px;
		position: relative;
		width: 32px;
		height: 32px;
	}

	&__item-icon {
		position: absolute;
		width: 80%;
		color: var(--color-primary-purple);
		&--plus {
			transition: all 0.25s ease-in-out;
			opacity: 1;
			position: absolute;
			left: 50%;
			top: 50%;
			transform: translate(-50%, -50%) rotate(90deg);

			.c-faqs__item.--is-active & {
				transform: translate(-50%, -50%) rotate(180deg);
				opacity: 1;
			}
		}

		&--minus {
			transform: rotate(0deg);
			transition: all 0.25s ease-in-out;
			position: absolute;
			left: 50%;
			top: 50%;
			transform: translate(-50%, -50%) rotate(0deg);
			opacity: 1;

			.c-faqs__item.--is-active & {
				transform: translate(-50%, -50%) rotate(90deg);
				opacity: 0;
			}
		}
	}
}

.u-display-none {
	display: none;
}

.u-display-block {
	display: block;
}

.u-display-inline-block {
	display: inline-block;
}

.u-display-inline {
	display: inline;
}

@each $size in $screen-sizes-mobile-first {
	@include screen-mobile-first($size) {
		.u-display-none\@#{$size} {
			display: none;
		}

		.u-display-block\@#{$size} {
			display: block;
		}

		.u-display-flex\@#{$size} {
			display: flex;
		}

		.u-display-inline-block\@#{$size} {
			display: block;
		}

		.u-display-inline\@#{$size} {
			display: inline;
		}
	}
}

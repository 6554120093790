.p-how-it-works {
	.c-section--page-header {
		overflow: hidden;
	}
	.c-page-header {
		@include screen(sm) {
			padding-bottom: 0;
		}
	}

	.c-page-header__buttons {
		flex-wrap: wrap;
		@include screen(xs) {
			.c-button {
				flex: 0 0 100%;
			}
		}
	}

	.modal {
		max-width: 80%;
		padding: 0;
	}
}

.p-how-it-works-video {
	position: absolute;
	right: 0;
	bottom: 0;
	width: 78rem;
	cursor: pointer;

	@include screen(xl) {
		right: -15%;
	}

	@include screen(lg) {
		right: -30%;
	}

	@include screen(md) {
		right: -65%;
	}

	@include screen(sm) {
		position: relative;
		right: inherit;
		bottom: inherit;
		// width: 140%;
		margin-top: 4rem;
		// left: -20%;
	}

	&__button {
		position: absolute;
		width: 100px;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
		svg {
			transition: all 0.2s var(--default-transition);
			filter: drop-shadow(0px 5px 15px rgba(13, 0, 79, 0.5));
		}
	}

	&:hover svg {
		transform: scale(1.2);
		transform-origin: center center;
	}
}

.p-how-it-works-demo-button {
	&:hover {
		transform: translateX(4px);
	}
}

.p-how-it-works-tour-section {
	overflow: hidden;
}

.p-onboarding {
	background: #f6f7f9;

	.gfield_required_text {
		display: none !important;
	}

	.gfield_required {
		&::before {
			content: '*';
			color: red;
		}
	}

	.gfield_label {
		font-weight: 700;
		font-size: 12px;
		margin-bottom: 4px;
		color: #000;
		display: block;
	}

	#field_1_5 {
		display: none;
	}

	svg {
		width: 90px;
		display: block;
		margin: 0 auto;
		height: 133px;
		left: -4px;
		position: relative;

		path {
			stroke: var(--color-primary-jade) !important;
		}
	}

	div#gform_confirmation_message_1 {
		font-weight: 600;
		text-align: center;
		font-size: 16px;
		line-height: 1.5;
		color: #000;
		margin-top: 84px;
		display: block;
		background: var(--color-purple-50);
		border: solid var(--color-primary-purple);
		border-radius: 8px;
		border-width: 2px;
		padding: 24px;
		color: var(--color-purple-900);

		strong {
			font-size: 21px;
			line-height: 1.2;
		}
	}

	input {
		display: block;
		width: 100%;
		font-size: 12px;
		line-height: 1.5;
		height: 40px;
		border-radius: 3px;
		border: 1px solid var(--color-gray-100);
		padding: 0 16px;
		position: relative;
		outline: none;
		color: #000;

		margin-bottom: 12px;

		&:focus {
			box-shadow: 0 0 0px 4px var(--color-purple-50);
			border-color: var(--color-primary-purple);
		}
	}

	.gfield_error input[type='text'],
	.gfield_error input[type='email'] {
		box-shadow: 0 0 0px 4px hsla(0deg 100% 50% / 0.1);
		border-color: red;
	}

	input[type='submit'] {
		appearance: none;
		border-radius: 8px 0;
		color: #fff;
		background-color: var(--color-primary-purple);
		border-color: var(--color-primary-purple);
		font-size: 14px;
		padding: 8px 24px;
		cursor: pointer;
		font-weight: 600;
		margin-top: 24px;
	}

	.gform_validation_errors {
		display: flex;
		background: #ffeeee;
		border: 2px solid #ffd1d1;
		border-radius: 4px;
		padding: 8px;
		margin-bottom: 16px;
	}

	.gfield_validation_message {
		color: red;
		font-weight: 600;
		position: relative;
		margin-bottom: 10px;
		margin-top: -10px;
		padding-left: 3px;
	}

	.gform_submission_error {
		font-weight: 600;
		font-size: 12px;
		color: #a32a2a;
	}
}

.p-onboarding .pace {
	display: none;
}

.p-onboarding .c-form__label {
	font-weight: 700;
}

.p-onboarding .c-form__group+.c-form__group {
	margin-top: 20px;
}

.p-onboarding__header {
	box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
}

.p-onboarding__header-inner {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 0 16px;
	height: 80px;
	width: 100%;
	max-width: 1320px;
	margin: 0 auto;
}

.p-onboarding__header-logo {}

.p-onboarding__header-logo img {
	max-height: 60px;
	max-width: 160px;
}

.p-onboarding-intro {
	display: flex;
	align-items: center;
	padding-top: 6%;
	padding-left: 16px;
	padding-right: 16px;
}

.p-onboarding-intro__inner {
	margin: 0 auto;
	width: 100%;
	max-width: 360px;
}

.p-onboarding-intro__logo img {
	margin: 0 auto;
	display: block;
	max-width: 90%;
	margin-bottom: 32px;
}

.p-onboarding__heading {
	margin: 0;
	padding: 0;
	font-weight: 700;
	font-size: 24px;
	text-align: center;
	margin-bottom: 8px;
}

@media (max-width: 380px) {
	.p-onboarding__heading {
		font-size: 20px;
	}
}

.p-onboarding__subheading {
	margin: 0;
	padding: 0;
	font-size: 14px;
	color: var(--color-gray-400);
	text-align: center;
	margin-bottom: 24px;
}

.p-onboarding-intro__subtext {
	text-align: center;
	font-size: 14px;
	color: var(--color-gray-300);
	margin-top: 24px;
}

.p-onboarding-intro__subtext a {
	color: var(--color-primary);
	text-decoration: none;
	text-underline-offset: 4px;
}

.p-onboarding-intro__subtext a:hover {
	color: var(--color-primary);
	text-decoration: underline;
}

.p-onboarding-confirmation {
	margin: 0 auto;
	width: 100%;
	max-width: 900px;
	padding: 0 16px;
	padding-top: 80px;
	display: flex;
	flex-wrap: wrap;
	padding-bottom: 80px;
}

@media (max-width: 900px) {
	.p-onboarding-confirmation {
		padding-top: 40px;
	}
}

.p-onboarding-confirmation__first {
	flex: 1;
	padding-right: 40px;
	margin-right: 40px;
	border-right: 2px solid var(--color-gray-50);
}

@media (max-width: 900px) {
	.p-onboarding-confirmation__first {
		padding: 0;
		margin: 0;
		border: none;
		border-bottom: 2px solid var(--color-gray-50);
		margin-bottom: 40px;
		padding-bottom: 40px;
		flex: 0 0 100%;
	}

	.p-onboarding-confirmation__last {
		flex: 0 0 100%;
		padding-bottom: 40px;
	}
}

.p-onboarding-confirmation__title {
	margin: 0;
	padding: 0;
	font-weight: 700;
	font-size: 24px;
	margin-bottom: 8px;
}

.p-onboarding-confirmation__subtitle {
	margin: 0;
	padding: 0;
	font-size: 14px;
	color: var(--color-gray-100);
}

.p-onboarding-confirmation__plan-details {
	font-size: 14px;
	color: var(--color-gray-500);
}

.p-onboarding-confirmation__plan-details .p-onboarding-confirmation__title {
	color: var(--color-primary);
	margin-bottom: 24px;
}

.p-onboarding-confirmation__plan-summary {
	padding-bottom: 24px;
	margin-bottom: 24px;
	border-bottom: 1px solid var(--color-gray-50);
}

.p-onboarding-confirmation__plan-summary p:last-child {
	margin-bottom: 0;
}

.p-onboarding-confirmation__plan-summary-title {
	margin-bottom: 12px;
}

.p-onboarding-confirmation__plan-features {}

.p-onboarding-confirmation__plan-feature {
	display: flex;
	align-items: center;
	gap: 8px;
}

.p-onboarding-confirmation__plan-feature+.p-onboarding-confirmation__plan-feature {
	margin-top: 8px;
}

.p-onboarding-confirmation__plan-feature svg {
	width: 16px;
	color: var(--color-secondary);
}

.p-onboarding-confirmation__plan-pricing {
	margin-top: 24px;
	display: flex;
	flex-wrap: nowrap;
	gap: 8px;
	align-items: center;
	padding-bottom: 24px;
	margin-bottom: 24px;
	border-bottom: 1px solid var(--color-gray-50);
}

.p-onboarding-confirmation__plan-pricing-info {
	display: flex;
	flex-direction: column;
}

.p-onboarding-confirmation__plan-price {
	font-size: 45px;
	font-weight: 700;
	line-height: 1;
}

.p-onboarding-confirmation__plan-price span {
	font-size: 20px;
	vertical-align: super;
}

.p-onboarding-confirmation__plan-pricing-text {
	font-size: 12px;
}

.p-onboarding-confirmation__plan-pricing-billing {
	font-size: 10px;
	color: var(--color-gray-100);
}

.p-onboarding-confirmation__terms-agreement {
	margin-bottom: 12px;
}

.p-onboarding-confirmation__secure {
	margin-top: 8px;
	font-size: 10px;
	display: flex;
	align-items: center;
	gap: 4px;
}

.p-onboarding-confirmation__section-header {
	background: var(--color-gray-25);
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 0 24px;
}

.p-onboarding-confirmation__section-header-title {
	margin: 0;
	padding: 0;
	font-size: 14px;
	font-weight: 700;
	line-height: 40px;
}

.p-onboarding-confirmation__section-edit {
	cursor: pointer;
	color: var(--color-primary);
	text-decoration: none;
}

.p-onboarding-confirmation__section {
	margin-top: 24px;
}

.p-onboarding-confirmation__item-label {
	margin: 0;
	padding: 0;
	font-weight: 700;
	font-size: 14px;
	margin-bottom: 12px;
}

div.p-onboarding-confirmation__field {
	margin: 0;
	padding: 0;
	font-size: 14px;
	line-height: 1.25;
}

div.p-onboarding-confirmation__field+div.p-onboarding-confirmation__field {
	margin-top: 8px;
}

.p-onboarding-confirmation__about-section {
	padding: 24px;
	display: flex;
	justify-content: space-between;
}

.p-onboarding-confirmation__about-section-item {
	flex: 0 0 46%;
}

.p-onboarding-confirmation__mid-section {
	padding: 24px;
}

.p-onboarding-confirmation__mid-section-header {
	display: flex;
	flex-wrap: nowrap;
}

.p-onboarding-confirmation__mid-section-header .p-onboarding-confirmation__item-label {
	flex: 0 0 47%;
}

.p-onboarding-confirmation__mid-section-header .p-onboarding-confirmation__item-label+.p-onboarding-confirmation__item-label {
	margin-left: 20px;
}

.p-onboarding-confirmation__mids {
	max-height: 200px;
	overflow-y: scroll;
}

.p-onboarding-confirmation__mids::-webkit-scrollbar {
	width: 6px;
	border-radius: 10px;
}

.p-onboarding-confirmation__mids::-webkit-scrollbar-thumb {
	background-color: var(--color-primary);
	border-radius: 10px;
}

.p-onboarding-confirmation__mids::-webkit-scrollbar-track {
	background-color: var(--color-gray-25);
}

.p-onboarding-confirmation__mids-item {
	display: flex;
	flex-wrap: nowrap;
}

.p-onboarding-confirmation__mid,
.p-onboarding-confirmation__descriptor {
	flex: 0 0 47%;
	font-size: 14px;
	margin-bottom: 4px;
}

.p-onboarding-confirmation__descriptor {
	margin-left: 20px;
}

.p-onboarding-confirmation__payment-details {
	padding-top: 24px;
	margin-top: 24px;
	border-top: 1px solid var(--color-gray-50);
}

.p-onboarding-confirmation__payment-form {
	margin-top: 24px;
}

.p-onboarding-confirmation__payment-form-card {
	display: flex;
	gap: 16px;
	margin-bottom: 16px;
}

@media (max-width: 560px) {
	.p-onboarding-confirmation__payment-form-card {
		flex-wrap: wrap;
	}
}

.p-onboarding-confirmation__payment-form-card .c-form__group+.c-form__group {
	margin: 0;
}

.p-onboarding-confirmation__payment-form-card .c-form__group {
	flex: 1;
}

@media (max-width: 560px) {
	.p-onboarding-confirmation__payment-form-card .c-form__group {
		flex: 0 0 100%;
	}
}

.p-onboarding-confirmation__payment-form-card-2 {
	display: flex;
	gap: 16px;
	margin-bottom: 16px;
}

@media (max-width: 560px) {
	.p-onboarding-confirmation__payment-form-card-2 {
		flex-wrap: wrap;
	}
}

.p-onboarding-confirmation__payment-form-card-2 .c-form__group+.c-form__group {
	margin: 0;
}

.p-onboarding-confirmation__payment-form-card-2 .c-form__group {
	flex: 1;
}

@media (max-width: 560px) {
	.p-onboarding-confirmation__payment-form-card-2 .c-form__group {
		flex: 0 0 100%;
	}
}

.p-onboarding-confirmation__about-section.is-editing .p-onboarding-confirmation__field {
	margin-bottom: 0;
}

.p-onboarding-confirmation__about-section.is-editing .p-onboarding-confirmation__field>span {
	display: block;
	margin-bottom: 16px;
}

.p-onboarding__steps {
	width: 100%;
	background: #f6f7f9;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-bottom: 60px;
}

.p-onboarding__steps-inner {
	margin: 0 auto;
	width: 100%;
	max-width: 900px;
	display: flex;
	flex-wrap: wrap;
}

.p-onboarding__steps-items {
	display: flex;
	justify-content: center;
	width: 100%;
	flex-wrap: wrap;
}

.p-onboarding__steps-item {
	border-bottom: 3px solid transparent;
	flex: 0 0 25%;
	display: flex;
	align-items: center;
	justify-content: center;
	height: 80px;
	cursor: pointer;
}

@media (max-width: 560px) {
	.p-onboarding__steps-item {
		flex-direction: column;
	}
}

.p-onboarding__steps-item.--active {
	border-color: var(--color-primary);
}

.p-onboarding__steps-count {
	background: var(--color-gray-50);
	color: #fff;
	border-radius: 100%;
	width: 1.7em;
	height: 1.7em;
	font-size: 14px;
	font-weight: 700;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-right: 16px;
}

@media (max-width: 560px) {
	.p-onboarding__steps-count {
		margin-right: 0;
		font-size: 12px;
	}
}

.p-onboarding__steps-item:hover {
	background: var(--color-gray-25);
}

.p-onboarding__steps-item.--active .p-onboarding__steps-count {
	background: var(--color-primary);
}

.p-onboarding__steps-title {
	text-transform: uppercase;
	font-weight: 600;
	font-size: 11px;
	color: #555469;
}

@media (max-width: 560px) {
	.p-onboarding__steps-title {
		text-align: center;
		font-size: 9px;
		margin-top: 8px;
	}
}

.p-onboarding-business__form,
.p-onboarding__merchant-accounts {
	max-width: 660px;
	margin: 0 auto;
	padding: 0 16px;
}

.p-onboarding-business__form .c-form__group+.c-form__group {
	margin: 0;
}

.p-onboarding-business__form-group {
	display: flex;
	align-items: flex-start;
	justify-content: center;
	gap: 16px;
	flex-wrap: wrap;
}

.p-onboarding-business__form-group .c-form__group {
	flex: 0 0 100%;
}

.p-onboarding-business__form-group--2 .c-form__group {
	flex: 0 0 calc(50% - 8px);
}

@media (max-width: 560px) {
	.p-onboarding-business__form-group--2 .c-form__group {
		flex: 0 0 100%;
	}
}

.p-onboarding-business__form-group+.p-onboarding-business__form-group {
	margin-top: 20px;
}

.p-onboarding-business__form-group .c-btn,
.p-onboarding__merchant-accounts-buttons .c-btn,
.p-onboarding__account-buttons .c-btn {
	margin-top: 20px;
	min-width: 200px;
}

.p-onboarding__merchant-accounts-buttons {
	display: flex;
	gap: 16px;
}

.p-onboarding__account .p-onboarding-confirmation__first {
	margin: 0;
	padding: 0;
	border: none;
	flex: 0 0 100%;
}

.p-onboarding__account .p-onboarding-confirmation {
	padding-top: 0;
}

.p-onboarding-confirmation__mids-item {
	padding: 4px 8px;
}

.p-onboarding-confirmation__mids-item:nth-of-type(even) {
	background: #fbfcfd;
}

.p-onboarding-confirmation__mid,
.p-onboarding-confirmation__descriptor {
	margin-bottom: 0;
}

.p-onboarding-complete-purchase {
	display: flex;
	gap: 4rem;
	flex-wrap: wrap;
}

.p-onboarding-complete-purchase__first {
	flex: 1;
}

.p-onboarding-complete-purchase__form-group {
	display: flex;
	align-items: flex-start;
	justify-content: center;
	gap: 16px;
	flex-wrap: wrap;
}

.p-onboarding-complete-purchase__form-group .c-form__group {
	flex: 1;
}

@media (max-width: 560px) {
	.p-onboarding-complete-purchase__form-group.--2 .c-form__group {
		flex: 0 0 100%;
	}
}

.p-onboarding-complete-purchase__form-group+.p-onboarding-complete-purchase__form-group {
	margin-top: 20px;
}

.p-onboarding-complete-purchase__form-group .c-form__group+.c-form__group {
	margin: 0;
}

.p-onboarding__container {
	margin: 0 auto;
	max-width: 900px;
	padding: 16px;
}

.p-onboarding-complete-purchase__form+.p-onboarding-complete-purchase__form {
	margin-top: 1.6rem;
	padding-top: 1.6rem;
	border-top: 2px solid var(--color-gray-25);
}

.p-onboarding-complete-purchase__form-title {
	margin: 0;
	padding: 0;
	font-weight: 700;
	font-size: 24px;
	margin-bottom: 8px;
}

@media (max-width: 380px) {
	.p-onboarding-complete-purchase__form-title {
		font-size: 20px;
	}
}

.p-onboarding-complete-purchase__form-subtitle {
	margin: 0;
	padding: 0;
	font-size: 14px;
	color: var(--color-gray-100);
	margin-bottom: 24px;
}

.p-onboarding-complete-purchase__aside {
	background: var(--app-dark-background);
	width: 360px;
	color: #fff;
	border-radius: 16px 0;
}

.p-onboarding-complete-purchase__aside-inner {
	padding: 32px;
}

.p-onboarding-complete-purchase__aside-label {
	font-size: 10px;
	font-weight: 700;
	text-transform: uppercase;
	color: var(--app-primary-color-on-dark);
}

.p-onboarding-complete-purchase__aside-plan-title {
	color: var(--color-secondary);
	font-weight: 700;
	font-size: 24px;
}

.p-onboarding-complete-purchase__aside-plan-description {
	font-size: 16px;
	margin: 0;
	margin-top: -8px;
	margin-bottom: 32px;
}

.p-onboarding-complete-purchase__aside-section-heading {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 6px 0;
	border-bottom: 1px solid #fff;
	margin-bottom: 16px;
}

.p-onboarding-complete-purchase__aside-section-title {
	font-size: 14px;
	font-weight: 700;
}

.p-onboarding-complete-purchase__aside-section-edit {
	font-size: 10px;
	color: var(--app-primary-color-on-dark);
	text-decoration: underline;
	cursor: pointer;
	display: block;
}

.p-onboarding-complete-purchase__list {
	margin: 0;
	padding: 0;
	list-style: none;
	font-size: 12px;
	font-weight: 700;
}

.p-onboarding-complete-purchase__list-item {
	margin-bottom: 6px;
	display: flex;
	align-items: center;
}

.p-onboarding-complete-purchase__list-item svg {
	margin-right: 8px;
}

.p-onboarding-complete-purchase__list.--dull span {
	opacity: 0.7;
	font-weight: 400;
}

.p-onboarding-complete-purchase__list:last-child {
	margin-bottom: 24px;
}

.p-onboarding-complete-purchase__summary-item {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.p-onboarding-complete-purchase__summary {
	display: flex;
	flex-direction: column;
	gap: 16px;
}

.p-onboarding-complete-purchase__summary-item-name {
	font-weight: 600;
	font-size: 12px;
	margin: 0;
}

.p-onboarding-complete-purchase__summary-item-cost {
	font-weight: 700;
	font-size: 14px;
	margin: 0;
}

.p-onboarding-complete-purchase__summary-item-cost span {
	font-size: 11px;
	font-weight: 400;
	opacity: 0.9;
}

.p-onboarding-complete-purchase__summary-item-billed {
	display: block;
	opacity: 0.6;
	font-size: 10px;
	line-height: 1;
}

.p-onboarding-complete-purchase__summary-item-last {
	width: 120px;
}

.p-onboarding-complete-purchase__billed-today {
	margin-top: 8px;
	border-radius: 8px;
	border: 1px solid var(--app-primary-color-on-dark);
	display: flex;
	align-items: center;
	font-weight: 700;
	font-size: 14px;
	justify-content: space-between;
	gap: 48px;
	padding: 14px 0;
}

.p-onboarding-complete-purchase__billed-today-text {
	padding-left: 40px;
}

.p-onboarding-complete-purchase__billed-today-value {
	width: 120px;
}

.p-onboarding-complete-purchase__additional {
	margin: 0;
	font-size: 10px;
	margin-top: -10px;
	opacity: 0.8;
	margin-bottom: 16px;
}

.p-onboarding-complete-purchase__submit-section .c-form__checkbox-label {
	font-size: 10px;
}

.p-onboarding-complete-purchase__submit-section .c-form__checkbox-label .url-mimic {
	color: var(--app-primary-color-on-dark);
	text-decoration: underline;
}

.p-onboarding-complete-purchase__submit-section .c-form__checkbox-box {
	border: none;
}

@media (max-width: 900px) {

	.p-onboarding-complete-purchase__first,
	.p-onboarding-complete-purchase__last {
		flex: 0 0 100%;
	}

	.p-onboarding-complete-purchase__aside {
		width: 100%;
	}
}

// generic page template stuff

:root {
	--spacing: 0.8rem;
	--space-xs: calc(var(--spacing) / 2);
	--space-1: var(--spacing);
	--space-2: calc(var(--spacing) * 2);
	--space-3: calc(var(--spacing) * 3);
	--space-4: calc(var(--spacing) * 4);
	--space-5: calc(var(--spacing) * 5);
	--space-6: calc(var(--spacing) * 6);
	--space-7: calc(var(--spacing) * 7);
	--space-8: calc(var(--spacing) * 8);
	--space-9: calc(var(--spacing) * 9);
	--space-10: calc(var(--spacing) * 10);
}

.s-prose {
	:where(p):not(:where([class~='prose-disabled'] *)) {
		font-size: var(--text-base);
		line-height: 1.75;
		margin-top: var(--space-4);
		margin-bottom: var(--space-4);
	}

	:where(h2):not(:where([class~='prose-disabled'] *)) {
		font-size: var(--text-xl);
		line-height: var(--lead-xl);
		font-weight: 700;
		margin-bottom: var(--space-2);
		margin-top: var(--space-6);
		color: var(--color-black);
	}

	:where(h3):not(:where([class~='prose-disabled'] *)) {
		font-size: var(--text-lg);
		line-height: var(--lead-lg);
		font-weight: 700;
		margin-bottom: var(--space-2);
		margin-top: var(--space-6);
		color: var(--color-black);
	}

	:where(ul):not(:where([class~='not-prose'] *)) {
		margin-left: var(--space-2);
	}

	:where(ol):not(:where([class~='not-prose'] *)) {
		margin-left: var(--space-2);
		list-style-type: decimal;
		padding-left: var(--space-2);
	}

	:where(li):not(:where([class~='not-prose'] *)) {
		margin-top: var(--space-2);
		margin-bottom: var(--space-2);
	}

	:where(ul > li):not(:where([class~='prose-disabled'] *)) {
		padding-left: var(--space-3);
		position: relative;
		font-size: var(--text-base);
		line-height: 1.5;
	}

	:where(ol > li):not(:where([class~='prose-disabled'] *)) {
		position: relative;
		font-size: var(--text-base);
		line-height: 1.5;
		padding-left: var(--space-xs);
	}

	:where(ul > li):not(:where([class~='not-prose'] *)):before {
		content: '';
		width: 0.8rem;
		height: 0.8rem;
		position: absolute;
		top: 0.8rem;
		left: 0.4rem;
		border-radius: 999px;
		background-color: var(--color-primary);
	}

	:where(ol > li):not(:where([class~='not-prose'] *))::marker {
		font-weight: 700;
		color: var(--color-primary);
	}

	:where(a):not(:where([class~='prose-disabled'] *)) {
		font-weight: 500;
		color: var(--color-primary);

		&:hover {
			color: var(--color-primary-lighter);
			border-bottom: 0.1rem solid var(--color-primary);
		}
	}

	:where(h2 + *):not(:where([class~='prose-disabled'] *)) {
		margin-top: 0;
	}

	:where(h3 + *):not(:where([class~='prose-disabled'] *)) {
		margin-top: 0;
	}

	:where(:first-child):not(:where([class~='prose-disabled'] *)) {
		margin-top: 0;
	}
}


.gfield--type-honeypot {
	display: none;
}
.o-container {
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;


	&__inner {
		position: relative;
		width: 100%;
		max-width: 132rem;
		padding: 0 var(--padding-gutter);

		&--old {
			max-width: 114rem;
		}

		@include screen(xl) {
			max-width: 114rem;
		}

		@include screen(lg) {
			max-width: 96rem;
		}

		@include screen(md) {
			max-width: 72rem;
		}

		@include screen(sm) {
			padding: 0 2.4rem;
			max-width: 54rem;
		}
	}
}

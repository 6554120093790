img,
svg,
video,
canvas,
audio,
iframe,
embed,
object {
  display: block;
  vertical-align: middle;
}

figure {
  margin: 0;
}

// Constrain images and videos to the parent width

img,
video {
  max-width: 100%;
  height: auto;
}

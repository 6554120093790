:root {
	--font-primary: 'Open Sans', sans-serif;
	--default-transition: cubic-bezier(0.4, 0, 0.2, 1);

	// Colors

	// Black and White
	--color-white: #fff;
	--color-black: #000;

	// Grays
	--color-gray-50: #f6f7f9;
	--color-gray-100: #e6e9ef;
	--color-gray-200: #d1d4e0;
	--color-gray-300: #b7bbcd;
	--color-gray-400: #9a9db7;
	--color-gray-500: #8082a3;
	--color-gray-600: #67668a;
	--color-gray-700: #555469;
	--color-gray-800: #393947;
	--color-gray-900: #25252d;
	--color-gray-950: #17171c;

	// Blues
	--color-blue-50: #e6e8ff;
	--color-blue-100: #cacdff;
	--color-blue-200: #9294ff;
	--color-blue-300: #5e5aff;
	--color-blue-400: #3121ff;
	--color-blue-500: #1b00e8;
	--color-blue-600: #1c00b0;
	--color-blue-700: #190078;
	--color-blue-800: #100040;
	--color-blue-900: #0f0333;
	--color-blue-950: #0e0626;

	--color-blurple-50: #edf0ff;
	--color-blurple-100: #dee4ff;
	--color-blurple-200: #c4ccff;
	--color-blurple-300: #a0aaff;
	--color-blurple-400: #6d70ff; // Base
	--color-blurple-500: #635bf9;
	--color-blurple-600: #543dee;
	--color-blurple-700: #482fd3;
	--color-blurple-800: #3a29aa;
	--color-blurple-900: #29216b;
	--color-blurple-950: #161036;

	// Purples
	--color-purple-50: #eff1fe;
	--color-purple-100: #e2e4fd;
	--color-purple-200: #cbcdfa;
	--color-purple-300: #abacf6;
	--color-purple-400: #8f89f0;
	--color-purple-500: #7d6de7;
	--color-purple-600: #6c4ed9; // Base
	--color-purple-700: #6042c0;
	--color-purple-800: #4e389b;
	--color-purple-900: #352a63;
	--color-purple-950: #1c1533;

	// Turquoise
	--color-jade-50: #f0fff8;
	--color-jade-100: #c2ffeb;
	--color-jade-200: #8affda;
	--color-jade-300: #42ffca;
	--color-jade-400: #0cebc7; // Base
	--color-jade-500: #00d6b6;
	--color-jade-600: #00ad96;
	--color-jade-700: #008979;
	--color-jade-800: #036c61;
	--color-jade-900: #074f47;
	--color-jade-950: #002624;

	// Mint
	--color-mint-50: #e9f9e7;
	--color-mint-100: #dcf7db;
	--color-mint-200: #c2f1c4;
	--color-mint-300: #a9ebb3; // Base
	--color-mint-400: #77df91;
	--color-mint-500: #46d476;
	--color-mint-600: #2ab365;
	--color-mint-700: #1e8153;
	--color-mint-800: #14573e;
	--color-mint-900: #0e3d2f;
	--color-mint-950: #092921;

	// Semantic color names
	--color-body: var(--color-gray-700);
	--color-primary-purple: var(--color-purple-600);
	--color-primary-mint: var(--color-mint-300);
	--color-primary-jade: var(--color-jade-400);
	--color-primary-blurple: var(--color-blurple-400);

	// Spacing
	--spacing: 0.8rem;
	--spacing-xs: calc(var(--spacing) / 2);
	--spacing-1: var(--spacing);
	--spacing-2: calc(var(--spacing) * 2);
	--spacing-3: calc(var(--spacing) * 3);
	--spacing-4: calc(var(--spacing) * 4);
	--spacing-5: calc(var(--spacing) * 5);
	--spacing-6: calc(var(--spacing) * 6);
	--spacing-7: calc(var(--spacing) * 7);
	--spacing-8: calc(var(--spacing) * 8);
	--spacing-9: calc(var(--spacing) * 9);
	--spacing-10: calc(var(--spacing) * 10);

	// Font Sizes
	--text-xs: 1.2rem;
	--text-sm: 1.4rem;
	--text-base: 1.6rem;
	--text-md: 1.8rem;
	--text-lg: 2rem;
	--text-xl: 2.4rem;
	--text-2xl: 3rem;
	--text-3xl: 3.6rem;
	--text-4xl: 4.8rem;
	--text-5xl: 6rem;
	--text-6xl: 7.2rem;
	--text-7xl: 9.6rem;
	--text-8xl: 1.28rem;

	// Line height for the above Font Sizes
	--lead-xs: 1.6rem;
	--lead-sm: 2rem;
	--lead-base: 2.4rem;
	--lead-md: 2.8rem;
	--lead-lg: 2.8rem;
	--lead-xl: 3.2rem;
	--lead-2xl: 3.6rem;
	--lead-3xl: 4rem;
	--lead-4xl: 1;
	--lead-5xl: 1;
	--lead-6xl: 1;
	--lead-7xl: 1;
	--lead-8xl: 1;
}

// Sass variables
$screen-sizes: xl, lg, md, sm, xs;
$screen-sizes-mobile-first: xs, sm, md, lg, xl;
